import { template as template_5d2c0e42e12a4ba2b19a3fcad6cc1330 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5d2c0e42e12a4ba2b19a3fcad6cc1330(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
