import { template as template_2743e9f3e70b40168f9d9f4bb13e68fd } from "@ember/template-compiler";
const WelcomeHeader = template_2743e9f3e70b40168f9d9f4bb13e68fd(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
