import { template as template_117cd0a5ed9d41c29d99c0ba2a7f92ee } from "@ember/template-compiler";
const FKControlMenuContainer = template_117cd0a5ed9d41c29d99c0ba2a7f92ee(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
